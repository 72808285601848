import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import siteSettings from '../../config/site-settings';
import {Language} from '../../config/language';

@Component({
  selector: 'app-sh-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  siteSettings = siteSettings;
  language = Language;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  gotoPortal(): void {
    if (location.href === 'https://map.geopavlodar.kz/layer-themes') {
      window.location.href = 'https://geopavlodar.kz/#/';
    } else {
      window.location.href = 'http://geopavlodar.kazgisa.kz/#/';
    }
  }
}
