import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {CoreComponent} from './core/core.component';

const routes: Routes = [
  {
    path: '', component: CoreComponent, children: [
      {path: 'layer-themes', loadChildren: () => import('./features/layer-themes/layer-themes.module').then(m => m.LayerThemesModule)},
      {path: 'layers-card', loadChildren: () => import('./features/layers-card/layers-card.module').then(m => m.LayersCardModule)},
      {path: 'object-card', loadChildren: () => import('./features/layer-object-card/layer-object-card.module')
          .then(m => m.LayerObjectCardModule)},
    ]
  },
  {path: '', redirectTo: 'layer-themes', pathMatch: 'full'},
  {path: '**', redirectTo: 'layer-themes'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
