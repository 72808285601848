import {Language} from './language';
import {Browser} from 'leaflet';

const siteSettings = {
  identity: null,
  language: Language.RUS,
  search: true,
  isClearAllLyrs: false,
  filter: [],
  isCoordinate: false
};

export default siteSettings;
