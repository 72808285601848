<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="siteSettings.language===language.KAZ">
    <p><strong>БАЙЛАНЫС</strong></p>
    <pre>
      Павлодар облысының цифрлық технологиялар департаменті

      140000, Павлодар қ., Астана көшесі, 61  <strong>uct@pavlodar.gov.kz</strong>
      
      Телефоны: +7 (7182)32-20-44
    </pre>
  </div>
  <div *ngIf="siteSettings.language===language.RUS || siteSettings.language===language.ENG">
    <p><strong>КОНТАКТЫ</strong></p>
    <pre>
      Управление цифровых технологий Павлодарской области

140000, г. Павлодар, улица Астана, 61  <strong>uct@pavlodar.gov.kz</strong>

Телефон: +7 (7182)32-20-44
    </pre>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'bottom.close' | translate}}</button>
</mat-dialog-actions>