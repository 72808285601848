import { Component, OnInit } from '@angular/core';
import { MapService } from 'src/app/shared/services/map.service';

@Component({
  selector: 'app-map-layers',
  templateUrl: './map-layers.component.html',
  styleUrls: ['./map-layers.component.scss'],
})
export class MapLayersComponent implements OnInit {
  constructor(private mapSvc: MapService) {}

  isMapLyrsVisible = false;

  ngOnInit(): void {}

  showLegendNames(): void {
    this.isMapLyrsVisible = !this.isMapLyrsVisible;
  }

  swtichLayer(baseLayer: string): void {
    this.mapSvc.getMap().removeLayer(this.mapSvc.currentLayer);
    switch (baseLayer) {
      // case 'hybrid': {
      //   this.mapSvc.currentLayer = this.mapSvc.googleHybridLayer;
      //   break;
      // }
      // case 'scheme': {
      //   this.mapSvc.currentLayer = this.mapSvc.googleRoadLayer;
      //   break;
      // }
      case 'osm': {
        this.mapSvc.currentLayer = this.mapSvc.osmLayer;
        break;
      }
      case 'googleSat': {
        this.mapSvc.currentLayer = this.mapSvc.googleSat;
        break;
      }
      case 'white': {
        this.mapSvc.currentLayer = this.mapSvc.white;
        break;
      }
      case 'whitemap': {
        this.mapSvc.currentLayer = this.mapSvc.whitemap;
        break;
      }
      // case 'blue': {
      //   this.mapSvc.currentLayer = this.mapSvc.blue;
      //   break;
      // }
    }
    this.mapSvc.currentLayer.addTo(this.mapSvc.getMap());
  }
}
