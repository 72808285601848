<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
  <!-- KAZ -->
  <div *ngIf="siteSettings.language===language.KAZ">
    <p><strong>Жобаның мақсаты - </strong>
    <pre>
      тұрғындарға Павлодар облысының электрондық картасы мен анықтамаларын қолжетімді ету.

      Сіз түрлі ұйымдардың орналасуы мен байланыс ақпараттары, жұмыс тәртібі және интернет мекен-жайын табасыз. 
      Бұдан өзге, қала қонақтары үшін қоғамдық көлік бағыттары мен аялдамалар, ХҚКО, аурухана, емхана, дәріхана, 
      қонақүй, мейрамхана, дәмхана, банкомат, АЖҚС және т.б. қоғамдық маңызды нысандар жайлы ақпарат пайдалы болады. 
      Кәсіпкерлерді жоспарланып отырған жер сатылымдары, олардың орналасуы, сатылымға қатысу шарттары мен өтінім қабылдау
       орындары жайлы ақпарат қызықты болады.

      Одан басқа халық үшін құқықбұзушылық фактілері мен жол-көлік апаттары жайлы ақпарат қолжетімді болады 
      (ҚР Бас прокуратурасының құқықтық статистика және арнайы есепке алу жөніндегі Комитетінің ресми мәліметтеріне сәйкес).
      
      Оған қоса қаланың әрбір тұрғыны мен қонақтары «Жолдаулар» қызметімен ашық люктар, заңсыз қоқыс тастау, 
      шұңқырлар сияқты өзекті мәселелерді суреті мен пайда болу орнын тіркеп, хабарлау арқылы қаланы басқару ісіне өз үлесін қосады!
      </pre>
  </div>

  <!-- RUS -->
  <div *ngIf="siteSettings.language===language.RUS || siteSettings.language===language.ENG">
    <p><strong>Цель проекта - </strong>
    <pre>
      является предоставление населению доступа к электронным картам и справочникам по Павлодарской области. 

      Здесь вы найдете информацию о местонахождении различных организаций с их контактными данными, режимами работ, а также интернет-адресами.
       Помимо этого, для гостей города будет полезна информация по автобусным маршрутам и остановкам, расположению ЦОНов, больниц, поликлиник, аптек,     
       гостиниц, ресторанов, кафе, банкоматов, АЗС и других социально важных объектов. 
       Предпринимателей заинтересует информация о планируемых земельных торгах с месторасположением земельных 
           участков, условиями участия и местами приема заявок.      
      
      Также для населения доступна информация о местах выявления фактов правонарушений и дорожно-транспортных происшествий 
      (согласно официальным данным Комитета правовой статистики и специальным учетам Генеральной прокуратуры РК).      
      
      Помимо этого каждый житель и гость города с помощью сервиса «Обращения» могут сообщить о проблемных ситуациях 
      (открытых люках, незаконных свалках, ямах и др.), с возможностью прикрепления фото и указания на карте мест их возникновения,
            тем самым присоединяясь к процессу управления любимым городом!      
     
      </pre>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'bottom.close' | translate}}</button>
</mat-dialog-actions>