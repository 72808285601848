import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
// import * as LGeo from "leaflet-geodesy"; // realtime calcarea
import { image } from 'html2canvas/dist/types/css/types/image';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class MapInitDrawGeomService {
  private map: L.Map;
  editLayers: L.FeatureGroup = new L.FeatureGroup(); // ***************** need new layer feature
  public drawSubject = new Subject();
  areaGeo: any;

  constructor(private http: HttpClient) {
    this.initLocalization();
  }

  public initNewTools(map: L.Map): void {
    map.addLayer(this.editLayers);

    let group1 = L.layerGroup();
    group1.addTo(map);

    var drawControl = new L.Control.Draw({
      position: 'topright',
      edit: {
        featureGroup: this.editLayers,
        remove: false,
      },
      draw: {
        polyline: false,
        circlemarker: false,
        marker: false,
        circle: false,
        rectangle: false,
      },
    });

    map.addControl(drawControl);

    let toolsTwo = document.querySelector('.leaflet-draw') as HTMLElement;
    toolsTwo.style.display = 'none';
    toolsTwo.style.top = '7rem';
    toolsTwo.style.right = '2px';
    let optionPolygon = {
      weght: 2,
      color: 'white',
      fillOpacity: 0.5,
      fillColor: 'grey',
      showMeasurements: true,
    };
    let removeTools = document.querySelector('.leaflet-draw-toolbar');
    let buttonR = document.createElement('button');
    removeTools.appendChild(buttonR);
    buttonR.classList.add('removeToolsBtn');

    buttonR.addEventListener('click', () => {
      map.removeLayer(this.editLayers);
    });
    map.on(L.Draw.Event.CREATED, (event: any) => {
      map.addLayer(this.editLayers);
      var layerEditAdll = new L.FeatureGroup();
      map.addLayer(layerEditAdll);
      const type = event.layerType;
      const layer = event.layer;
      const geomObj = this.parseCoords(layer._latlngs[0]);
      let polygon = {
        geom: geomObj,
      };

      if (type == 'polygon') {
        this.editLayers.clearLayers();
        this.areaGeo = L.polygon(event.layer._latlngs, optionPolygon).addTo(
          this.editLayers
        );
        this.getAreaPolygon(polygon).subscribe((data) => {
          let areaPoly = data;
          this.areaGeo
            .bindPopup(
              `Площадь: <b>${areaPoly.area.toFixed(
                2
              )}</b> м² <br> Стоимость приблизительная:<b> ${areaPoly.all_price.toLocaleString(
                'ru-RU'
              )}</b> тенге`
            )
            .openPopup();
        });
      }
    });

    map.on('draw:editstop', (event) => {
      //after the end of editing, we collect all the data, we story a new polygon and request the data from the service
      let obj = event.target._targets;
      var valEventObjProp: any = Object.values(obj);
      const geomLayerLatLngs = valEventObjProp[1]?._latlngs;
      var geomResultat = this.parseCoords(geomLayerLatLngs[0]);

      let polygon = {
        geom: geomResultat,
      };
      this.editLayers.clearLayers();
      this.areaGeo = L.polygon(geomLayerLatLngs, optionPolygon).addTo(
        this.editLayers
      );
      this.getAreaPolygon(polygon).subscribe((data) => {
        let areaPoly = data;
        this.areaGeo
          .bindPopup(
            `Площадь: <b>${areaPoly.area.toFixed(
              2
            )}</b> м² <br> Стоимость приблизительная:<b> ${areaPoly.all_price.toLocaleString(
              'ru-RU'
            )}</b> тенге`
          )
          .openPopup();
      });
    });
  }

  public getAreaPolygon(polygonBody = null): Observable<any> {
    const url: string = `${environment.api}/valueland/geometry`;
    // const token = localStorage.getItem('access_token');
    const options = {
      headers: new HttpHeaders({
        // 'Authorization': 'Bearer ',
        'Content-Type': 'application/json',
      }),
      body: polygonBody,
    };
    return this.http.post(url, polygonBody, options);
  }

  private parseCoords(arr: Array<any>) {
    let str: string = '';
    let str2: string = '';
    let arrayStr: Array<any> = [];
    for (let i: number = 0; i < arr.length; i++) {
      const x = this.cutCoords(arr[i].lat);
      const y = this.cutCoords(arr[i].lng);
      str = y + ' ' + x;
      arrayStr.push(str);
      str = '';
    }
    {
      str = this.cutCoords(arr[0].lng) + ' ' + this.cutCoords(arr[0].lat);
      arrayStr.push(str);
      str = '';
    }
    arrayStr.join(',');
    str2 = 'POLYGON((' + arrayStr + '))';
    return str2;
  }

  private cutCoords(coordinate: any): string {
    const startIndex = String(coordinate).indexOf('.');
    return String(coordinate).slice(0, startIndex + 7);
  }

  initLocalization() {
    const drawToolbar = {
      actions: {
        title: 'Отменить рисование',
        text: 'Отменить',
      },
      finish: {
        title: 'Закончить рисование',
        text: 'Закончить',
      },
      undo: {
        title: 'Удалить последнюю нарисованную точку',
        text: 'Удалить последнюю точку',
      },
      buttons: {
        polyline: 'Нарисовать полилинию',
        polygon: 'Нарисовать полигон',
        rectangle: 'Нарисовать прямоугольник',
        circle: 'Нарисовать окружность',
        marker: 'Нарисовать маркер',
        circlemarker: 'Нарисовать круг маркер',
      },
    };

    const drawHandlers = {
      circle: {
        tooltip: {
          start: 'Нажмите и перетащите, чтобы нарисовать круг.',
        },
        radius: 'Радиус',
      },
      circlemarker: {
        tooltip: {
          start: 'Нажмите на карту, чтобы разместить круг маркер.',
        },
      },
      marker: {
        tooltip: {
          start: 'Нажмите на карту, чтобы разместить маркер.',
        },
      },
      polygon: {
        error: '<strong>Ошибка:</strong>!',
        tooltip: {
          start: 'Нажмите, чтобы начать рисовать фигуру.',
          cont: 'Нажмите, чтобы продолжить рисование фигуры.',
          end: 'Нажмите первую точку, чтобы замкнуть эту фигуру.',
        },
      },
      polyline: {
        error: '<strong>Ошибка:</strong> края формы не могут пересекаться!',
        tooltip: {
          start: 'Нажмите, чтобы начать рисовать линию.',
          cont: 'Нажмите, чтобы продолжить рисование линию.',
          end: 'Нажмите последнюю точку для завершения линии.',
        },
      },
      rectangle: {
        tooltip: {
          start: 'Нажмите и перетащите, чтобы нарисовать прямоугольник.',
        },
      },
      simpleshape: {
        tooltip: {
          end: 'Отпустите мышь, чтобы закончить рисование.',
        },
      },
    };

    const editToolbar = {
      actions: {
        save: {
          title: 'Сохранить изменения.',
          text: 'Сохранить',
        },
        cancel: {
          title: 'Отмена редактирования, сбрасывает все изменения.',
          text: 'Отмена',
        },
        clearAll: {
          title: 'Очистить все слои.',
          text: 'Очистить все',
        },
      },
      buttons: {
        edit: 'Изменить слои.',
        editDisabled: 'Нет слоев для редактирования.',
        remove: 'Удалить слои.',
        removeDisabled: 'Нет слоев для удаления.',
      },
    };

    const editHandlers = {
      edit: {
        tooltip: {
          text: 'Перетащите ручки или маркер для редактирования.',
          subtext: 'Нажмите «Отмена», чтобы отменить изменения.',
        },
      },
      remove: {
        tooltip: {
          text: 'Нажмите на объект, чтобы удалить',
        },
      },
    };

    (L as any).drawLocal.draw.toolbar = drawToolbar;
    (L as any).drawLocal.draw.handlers = drawHandlers;
    (L as any).drawLocal.edit.toolbar = editToolbar;
    (L as any).drawLocal.edit.handlers = editHandlers;
  }
}

declare module 'leaflet' {
  namespace control {
    function coordinates(obj: {
      position: string;
      useDMS: boolean;
      labelTemplateLat: string;
      labelTemplateLng: string;
      useLatLngOrder: boolean;
    }): any;
  }
}

export interface DrawOptions {
  drawEvent: string;
  layer: any;
  type?: any;
}
