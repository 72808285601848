import {Injectable} from '@angular/core';

import * as L from 'leaflet';
import {LeafletMouseEvent, PopupOptions, TileLayerOptions} from 'leaflet';
import {BehaviorSubject, Subject} from 'rxjs';

import {MapService} from './map.service';
import {ILayer, ILayerGroup} from '../models';
import * as WKT from 'terraformer-wkt-parser';
import {Point} from 'geojson';
import {IAuction, ICompetition, ICrimeObj, IDtp, IEvents, IFreeLand, IFuncZones, ISocialObjInfo} from '../portal.models';

import {environment} from '../../../environments/environment';
import {PortalApiService} from './portal-api.service';
import {TranslateService} from '@ngx-translate/core';
import siteSettings from '../config/site-settings';
import {HttpClient} from '@angular/common/http';
import {LayersApiService} from './layers-api.service';
import {Language} from '../config/language';
import {MapControlsService} from './map-controls.service';
import { GEOMETRYTYPES } from '../constants/geometry-types';

@Injectable({
  providedIn: 'root'
})
export class LayersManagerService {
  visibleLayers = new BehaviorSubject<L.TileLayer[]>([]);
  visibleLayers$ = this.visibleLayers.asObservable();

  featureGroup = L.featureGroup();
  layerData: any[] = [];
  searchMarker: L.Marker;
  searchMarkerAddressList: L.Marker[] = [];

  map: L.Map;
  drawMarker;
  selectedObj;
  public editableLayers: L.FeatureGroup = new L.FeatureGroup();
  public drawSubject = new Subject();
  preparedWktString = new Subject<string>();

  constructor(
    private mapService: MapService,
    private portalApiService: PortalApiService,
    private translateService: TranslateService,
    private layersApiService: LayersApiService,
    private mapControlsService: MapControlsService,
    private mapSvc: MapService,
    private http: HttpClient
  ) {
  }

  toggleLayerGroup(layer: ILayerGroup): void {
    if (!layer) {
      return;
    }
    this.toggleLyr(layer.layerName, layer.source.url, layer);
  }

  toggleLayer(layer: ILayer): void {
    if (!layer) {
      return;
    }
    this.toggleLyr(layer.layerName, layer.layerGroup.source.url, layer);
  }

  changeLayerGroupOpacity(layer: ILayerGroup, val): void {

    if (!layer) {
      return;
    }

    const map = this.mapService.getMap();
    let searchLyr = null;
    map.eachLayer((lyr: any) => {
      const el = lyr.options.layers;

      if (el && el === layer.layerName) {
        searchLyr = lyr;
      }
    });

    if (searchLyr) {
      searchLyr.setOpacity(1 - val);
    }

  }

  changeLayerGroupZoom(layer: ILayerGroup): void {

    if (!layer) {
      return;
    }

    const bbox = this.bboxFormatToLatLng(layer.bbox);
    const map = this.mapService.getMap();
    map.fitBounds(bbox);
    // let searchLyr = null;
    // map.eachLayer((lyr: any) => {
    //   const el = lyr.options.layers;
    //
    //   if (el && el === layer.layerName) {
    //     searchLyr = lyr;
    //   }
    // });
    //
    // if (searchLyr) {
    //   map.setView([42.896, 71.393], 14);
    //   // map.setView(searchLyr.getBounds().getCenter(), 14);
    // }
  }

  public bboxFormatToLatLng(bbox: string): any {

    const bboxParse = JSON.parse(bbox);
    const south = bboxParse._southWest;
    const north = bboxParse._northEast;
    const southArr = [[south.lat, south.lng]];
    const northArr = [[north.lat, north.lng]];
    const bboxFormat = southArr.concat(northArr);

    return bboxFormat;
  }


  /* аукцион*/
  getAuctionHtml(list: IAuction[]): string {

    let lotNomer = '';
    this.translateService.get('landTrade.lotNomer').subscribe(res => {
      lotNomer = res;
    });

    let plotAddress = '';
    this.translateService.get('landTrade.plotAddress').subscribe(res => {
      plotAddress = res;
    });

    let specialPurpose = '';
    this.translateService.get('landTrade.specialPurpose').subscribe(res => {
      specialPurpose = res;
    });

    let area = '';
    this.translateService.get('landTrade.area').subscribe(res => {
      area = res;
    });

    let auctionDate = '';
    this.translateService.get('landTrade.auctionDate').subscribe(res => {
      auctionDate = res;
    });

    let plotUsingLimit = '';
    this.translateService.get('landTrade.plotUsingLimit').subscribe(res => {
      plotUsingLimit = res;
    });

    let rentCondition = '';
    this.translateService.get('landTrade.rentCondition').subscribe(res => {
      rentCondition = res;
    });

    let plotCadastreCost = '';
    this.translateService.get('landTrade.plotCadastreCost').subscribe(res => {
      plotCadastreCost = res;
    });

    let plotStartCost = '';
    this.translateService.get('landTrade.plotStartCost').subscribe(res => {
      plotStartCost = res;
    });

    let plotTaxtCost = '';
    this.translateService.get('landTrade.plotTaxtCost').subscribe(res => {
      plotTaxtCost = res;
    });

    let lotParticipationCost = '';
    this.translateService.get('landTrade.lotParticipationCost').subscribe(res => {
      lotParticipationCost = res;
    });

    let auctionMetod = '';
    this.translateService.get('landTrade.auctionMetod').subscribe(res => {
      auctionMetod = res;
    });

    let auctionPlace = '';
    this.translateService.get('landTrade.auctionPlace').subscribe(res => {
      auctionPlace = res;
    });

    let auctionRequestAddress = '';
    this.translateService.get('landTrade.auctionRequestAddress').subscribe(res => {
      auctionRequestAddress = res;
    });

    let comment = '';
    this.translateService.get('landTrade.comment').subscribe(res => {
      comment = res;
    });

    let customer = '';
    this.translateService.get('landTrade.customer').subscribe(res => {
      customer = res;
    });

    let ownership = '';
    this.translateService.get('landTrade.ownership').subscribe(res => {
      ownership = res;
    });

    let html = '<div style="height:500px;">';
    list.forEach((item, index) => {

      const dtStr = this.getDateStr(item.auction_time);

      html += '<table style="width:100%; padding: 5px 5px 15px 5px;">'
        + `<tr style="font-weight: 900"><td colspan="2">Аукцион</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${lotNomer}</td><td>${item.lot_number}</td></tr>`      // Номер лота
        + `<tr><td style="width:200px;font-weight: bold">${plotAddress}</td><td>${siteSettings.language === 3 ? item.address_kaz : item.address_rus}</td></tr>`                // Местоположение замельного участка
        + `<tr><td style="width:200px;font-weight: bold">${specialPurpose}</td><td>${siteSettings.language === 3 ? item.target_kaz : item.target_rus}</td></tr>`    // Целевое назначение
        + `<tr><td style="width:200px;font-weight: bold">${area}</td><td>${item.area}</td></tr>` // Площадь
        + `<tr><td style="width:200px;font-weight: bold">${auctionDate}</td><td>${dtStr}</td></tr>` // Дата и время проведения аукциона
        + `<tr><td style="width:200px;font-weight: bold">${plotUsingLimit}</td><td>${siteSettings.language === 3 ? item.using_limit_kaz : item.using_limit_rus}</td></tr>`                        //
        + `<tr><td style="width:200px;font-weight: bold">${rentCondition}</td><td>${siteSettings.language === 3 ? item.rent_condition_kaz : item.rent_condition_rus}</td></tr>`                        //
        + `<tr><td style="width:200px;font-weight: bold">${plotCadastreCost}</td><td>${item.cadastre_cost}</td></tr>`  //
        + `<tr><td style="width:200px;font-weight: bold">${plotStartCost}</td><td>${item.start_cost}</td></tr>`  //
        + `<tr><td style="width:200px;font-weight: bold">${plotTaxtCost}</td><td>${item.tax_cost}</td></tr>`  //
        + `<tr><td style="width:200px;font-weight: bold">${lotParticipationCost}</td><td>${item.participation_cost}</td></tr>`  //
        + `<tr><td style="width:200px;font-weight: bold">${auctionMetod}</td><td>${item.auction_method_id === 1 ? 'английский' : 'голландский'}</td></tr>` //
        + `<tr><td style="width:200px;font-weight: bold">${auctionPlace}</td><td>${siteSettings.language === 3 ? item.auction_place_kaz : item.auction_place_rus}</td></tr>`                        //
        + `<tr><td style="width:200px;font-weight: bold">${auctionRequestAddress}</td><td>${siteSettings.language === 3 ? item.request_address_kaz : item.request_address_rus}</td></tr>`                        //
        + `<tr><td style="width:200px;font-weight: bold">${comment}</td><td>${siteSettings.language === 3 ? item.comment_kaz : item.comment_rus}</td></tr>`                        //
        + `<tr><td style="width:200px;font-weight: bold">${customer}</td><td>${item.customer1}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${ownership}</td><td>${item.ownership}</td></tr>`;  // Право владение

      if (item.customer2 && item.customer2 !== '') {
        html += `<tr><td style="width:200px;font-weight: bold">${customer}</td><td>${item.customer2}</td></tr>`;
      }

      html += `</table>`;

      if (index !== (list.length - 1)) {
        html += '<hr>';
      }
    });

    html += '</div>';

    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }

    return html;
  }

  showAuctionInfo(item: IAuction): void {

    if (item.geom === null || item.geom === '') {
      return;
    }
    const map = this.mapService.getMap();
    map.addLayer(this.featureGroup);

    const geometry = WKT.parse(item.geom) as Point;
    const multipolygon = geometry.coordinates;
    const positionArr: any[] = [];

    multipolygon.forEach((multi: any) => {
      const arr1: any[] = [];
      multi.forEach((multi2) => {
        const arr2: any[] = [];
        multi2.forEach(multi3 => {
          const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(multi3[0], multi3[1]));

          arr2.push([unprojPoint.lat, unprojPoint.lng]);
        });
        arr1.push(arr2);
      });

      positionArr.push(arr1);

    });

    const polygon = L.polygon(positionArr);
    // this.featureGroup.addLayer(polygon);
    // map.setView(this.featureGroup.getBounds().getCenter(), 12);
    const customOptions: PopupOptions =
      {
        maxWidth: 700,
        className: 'popupCustom'
      };

    // polygon.bindPopup('', customOptions);

    let html = this.getAuctionHtml([item]);
    html = `<div class="modal-toolbar"></div><div class="modal-content">${html}</div> `;

    const popup = L.popup(customOptions)
      .setLatLng(polygon.getBounds().getCenter())
      .setContent(`${html}`)
      .openOn(map);
    // const layerId = this.featureGroup.getLayerId(polygon);
    // this.layerData.push({gid: item.id, layerId});
  }

  /* конкурс*/
  addCompetitionLayers(item: ICompetition): void {

    if (item.geom === null || item.geom === '') {
      return;
    }
    const map = this.mapService.getMap();
    map.addLayer(this.featureGroup);

    const geometry = WKT.parse(item.geom) as Point;
    const multipolygon = geometry.coordinates;
    const positionArr: any[] = [];

    multipolygon.forEach((multi: any) => {

      const arr1: any[] = [];
      multi.forEach((multi2) => {
        const arr2: any[] = [];
        multi2.forEach(multi3 => {
          const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(multi3[0], multi3[1]));

          arr2.push([unprojPoint.lat, unprojPoint.lng]);
        });
        arr1.push(arr2);
      });

      positionArr.push(arr1);

    });

    const polygon = L.polygon(positionArr);
    this.featureGroup.addLayer(polygon);
    map.setView(this.featureGroup.getBounds().getCenter(), 12);
    const customOptions: PopupOptions =
      {
        maxWidth: 700,
        className: 'popupCustom'
      };

    polygon.bindPopup('', customOptions);
    const layerId = this.featureGroup.getLayerId(polygon);
    this.layerData.push({gid: item.id, layerId});

  }

  getCompetitionHtml(list: ICompetition[]): string {

    let lotNomer = '';
    this.translateService.get('landTrade.lotNomer').subscribe(res => {
      lotNomer = res;
    });

    let plotAddress = '';
    this.translateService.get('landTrade.plotAddress').subscribe(res => {
      plotAddress = res;
    });

    let specialPurpose = '';
    this.translateService.get('landTrade.specialPurpose').subscribe(res => {
      specialPurpose = res;
    });

    let area = '';
    this.translateService.get('landTrade.area').subscribe(res => {
      area = res;
    });

    let comptitionDeadline = '';
    this.translateService.get('landTrade.comptitionDeadline').subscribe(res => {
      comptitionDeadline = res;
    });

    let leaseYear = '';
    this.translateService.get('landTrade.leaseYear').subscribe(res => {
      leaseYear = res;
    });

    let economicCenterDestination = '';
    this.translateService.get('landTrade.economicCenterDestination').subscribe(res => {
      economicCenterDestination = res;
    });

    let regionalCenterDestination = '';
    this.translateService.get('landTrade.regionalCenterDestination').subscribe(res => {
      regionalCenterDestination = res;
    });

    let startCost = '';
    this.translateService.get('landTrade.startCost').subscribe(res => {
      startCost = res;
    });

    let margin = '';
    this.translateService.get('landTrade.margin').subscribe(res => {
      margin = res;
    });

    let comtititionDateTime = '';
    this.translateService.get('landTrade.comtititionDateTime').subscribe(res => {
      comtititionDateTime = res;
    });

    let plotUsingCondition = '';
    this.translateService.get('landTrade.plotUsingCondition').subscribe(res => {
      plotUsingCondition = res;
    });

    let comment = '';
    this.translateService.get('landTrade.comment').subscribe(res => {
      comment = res;
    });

    let html = '';
    list.forEach((item, index) => {

      const dtStr = this.getDateStr(item.competition_time);
      const deadline = this.getDateStr(item.deadline);

      html += '<table style="width:100%">'
        + '<tr style="font-weight: 900"><td colspan="2">Конкурс</td></tr>'
        + `<tr><td style="width:200px;font-weight: bold">${lotNomer}</td><td>${item.lot_number}</td></tr>`       // Номер лота
        + `<tr><td style="width:200px;font-weight: bold">${plotAddress}</td><td>${(siteSettings.language === 3) ? item.address_kaz : item.address_rus}</td></tr>`  // Местоположение замельного участка
        + `<tr><td style="width:200px;font-weight: bold">${specialPurpose}</td><td>${(siteSettings.language === 3) ? item.target_kaz : item.target_rus}</td></tr>`  // Целевое назначение
        + `<tr><td style="width:200px;font-weight: bold">${area}</td><td>${item.area}</td></tr>`   // Площадь
        // tslint:disable-next-line:max-line-length
        + `<tr><td style="width:200px;font-weight: bold">${comptitionDeadline}</td><td>${deadline}</td></tr>` // Окончательный срок подачи заявок на участие в конкурсе
        + `<tr><td style="width:200px;font-weight: bold">${leaseYear}</td><td>${item.lease}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${economicCenterDestination}</td><td>${siteSettings.language === 3 ? item.economic_center_kaz : item.economic_center_rus}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${regionalCenterDestination}</td><td>${siteSettings.language === 3 ? item.regional_center_kaz : item.regional_center_rus}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${startCost}</td><td>${item.start_cost}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${margin}</td><td>${item.margin}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${comtititionDateTime}</td><td>${dtStr}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${plotUsingCondition}</td><td>${siteSettings.language === 3 ? item.using_condition_kaz : item.using_condition_rus}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${comment}</td><td>${siteSettings.language === 3 ? item.comment_kaz : item.comment_rus}</td></tr>`
        + `</table>`;

      if (index !== (list.length - 1)) {
        html += '<hr>';
      }
    });

    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }
    return html;
  }

  showCompetitionInfo(item: ICompetition): void {

    if (item.geom === null || item.geom === '') {
      return;
    }
    const map = this.mapService.getMap();
    map.addLayer(this.featureGroup);

    const geometry = WKT.parse(item.geom) as Point;
    const multipolygon = geometry.coordinates;
    const positionArr: any[] = [];

    multipolygon.forEach((multi: any) => {

      const arr1: any[] = [];
      multi.forEach((multi2) => {
        const arr2: any[] = [];
        multi2.forEach(multi3 => {
          const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(multi3[0], multi3[1]));

          arr2.push([unprojPoint.lat, unprojPoint.lng]);
        });
        arr1.push(arr2);
      });

      positionArr.push(arr1);

    });

    const polygon = L.polygon(positionArr);
    // this.featureGroup.addLayer(polygon);
    // map.setView(this.featureGroup.getBounds().getCenter(), 12);
    const customOptions: PopupOptions =
      {
        maxWidth: 700,
        className: 'popupCustom'
      };
    const html = this.getCompetitionHtml([item]);
    const popup = L.popup(customOptions)
      .setLatLng(polygon.getBounds().getCenter())
      .setContent(`${html}`)
      .openOn(map);
  }

  showCompetitionInfoOld(item: ICompetition): void {

    if (item.geom === null || item.geom === '') {
      return;
    }

    let lyr = null;
    const selectedLayer = this.layerData.find(x => x.gid === item.id);

    this.featureGroup.eachLayer((l) => {

      const layerId = this.featureGroup.getLayerId(l);
      if (layerId === selectedLayer.layerId) {
        lyr = l;
      }
    });

    lyr.openPopup();
    const map = this.mapService.getMap();
    map.setView(lyr.getBounds().getCenter(), 17);
  }

  /* Справочник организаций */
  addSocialObjLayers(item: ISocialObjInfo): void {

    if (item.geom === null || item.geom === '') {
      return;
    }

    const map = this.mapService.getMap();
    map.addLayer(this.featureGroup);

    const geometry = WKT.parse(item.geom) as Point;
    const point = geometry.coordinates;
    const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(point[0], point[1]));

    const marker = L.marker(unprojPoint);
    const customOptions: PopupOptions =
      {
        maxWidth: 700,
        className: 'popupCustom'
      };

    marker.bindPopup('', customOptions);
    this.featureGroup.addLayer(marker);
    map.setView(this.featureGroup.getBounds().getCenter(), 12);
    const layerId = this.featureGroup.getLayerId(marker);
    this.layerData.push({gid: item.gid, layerId});
  }

  getSocialObjHtml(list: ISocialObjInfo[]): string {
    let socialObj = '';
    this.translateService.get('socialObj.object').subscribe(res => {
      socialObj = res;
    });

    let name = '';
    this.translateService.get('socialObj.name').subscribe(res => {
      name = res;
    });

    let address = '';
    this.translateService.get('socialObj.address').subscribe(res => {
      address = res;
    });

    let contact = '';
    this.translateService.get('socialObj.contact').subscribe(res => {
      contact = res;
    });

    let workTime = '';
    this.translateService.get('socialObj.workTime').subscribe(res => {
      workTime = res;
    });

    let description = '';
    this.translateService.get('socialObj.description').subscribe(res => {
      description = res;
    });

    let html = '';
    const obj = [];
    list.forEach((item, index) => {
      let helpHtml = '';
      helpHtml += '<table style="width:100%">'
        + `<tr style="font-weight: 900"><td colspan="2">${socialObj}</td></tr>` // Объект
        + `<tr><td style="width:200px;font-weight: bold">${name}</td><td>${(siteSettings.language === 3) ? item.name_kaz : item.name_rus}</td></tr>`   // Наименование
        + `<tr><td style="width:200px;font-weight: bold">${address}</td><td>${(siteSettings.language === 3) ? item.address_kaz : item.address_rus}</td></tr>`  // Адрес
        + `<tr><td style="width:200px;font-weight: bold">${contact}</td><td>${item.contact}</td></tr>`  // Контакты
        + `<tr><td style="width:200px;font-weight: bold">${workTime}</td><td>${item.work_time}</td></tr>`  // Рабочее время
        + `<tr><td style="width:200px;font-weight: bold">${description}</td><td>${(siteSettings.language === 3) ? item.description_kaz : item.description_rus}</td></tr>`  // Описание
        + '</table>';

      obj.push(helpHtml);
    });

    html = obj.join('<hr>');
    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }


    return html;
  }

  showSocialObjInfo(item: ISocialObjInfo): void {
    const map = this.mapService.getMap();

    const geometry = WKT.parse(item.geom) as Point;
    const point = geometry.coordinates;
    const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(point[0], point[1]));

    let html = this.getSocialObjHtml([item]);
    html = `<div class="modal-toolbar"></div><div class="modal-content">${html}</div> `;

    const popup = L.popup()
      .setLatLng(unprojPoint)
      .setContent(`${html}`)
      .openOn(map);
  }

  showSocialObjInfoOld(item: ISocialObjInfo): void {

    if (item.geom === null || item.geom === '') {
      return;
    }

    let lyr = null;
    const selectedLayer = this.layerData.find(x => x.gid === item.gid);

    this.featureGroup.eachLayer((l) => {

      const layerId = this.featureGroup.getLayerId(l);
      if (layerId === selectedLayer.layerId) {
        lyr = l;
      }
    });

    lyr.openPopup();
    const map = this.mapService.getMap();
    map.setView(lyr.getLatLng(), 17);
  }

  /* Карат ДТП */
  getDtpHtml(list: IDtp[]): string {

    let html = '';
    list.forEach((item, index) => {

      const dtStr = this.getDateStr(item.rta_date);

      html += '<table style="width:100%">'
        + '<tr style="font-weight: 900"><td colspan="2">Объект (ДТП)</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Дата</td><td>' + dtStr + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Cостояние дорожного покрытия</td><td>' + item.road_surface + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Освещенность</td><td>' + item.light + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Нарушение ПДД</td><td>' + item.trafic_violation + '</td></tr>'

        + '<tr><td style="width:200px;font-weight: bold">Виновный</td><td>' + item.offender + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Состояние виновного</td><td>' + item.offender_state + '</td></tr>'

        + '<tr><td style="width:200px;font-weight: bold">Количество раненых</td><td>' + item.wounded_count + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Количество погибших</td><td>' + item.dead_count + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Адрес</td><td>' + item.address + '</td></tr>'
        + '</table>';

      if (index !== (list.length - 1)) {
        html += '<hr>';
      }

    });

    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }
    return html;
  }

  /* правонарушения*/
  getCrimeHtml(list: ICrimeObj[]): string {

    let html = '';
    list.forEach((item, index) => {

      html += '<table style="width:100%">'
        + '<tr style="font-weight:900"><td colspan="2">Карта преступности</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Название преступления</td><td>' + item.crime_code_name + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Тяжесть преступления</td><td>' + item.hard_code_name + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Дата и время регистрации преступления</td><td>' + item.crime_datetime + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Орган регистрации преступления</td><td>' + item.organ + '</td></tr>'
        + '<tr><td style="width:200px;font-weight: bold">Статья УК</td><td>' + item.stat + '</td></tr>'
        + '</table>';

      if (index !== (list.length - 1)) {
        html += '<hr>';
      }
    });

    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }

    return html;
  }

  showCrimeInfo(item: ICrimeObj): void {

    if (item.geom === null || item.geom === '') {
      return;
    }

    let lyr = null;
    const selectedLayer = this.layerData.find(x => x.gid === item.gid);

    this.featureGroup.eachLayer((l) => {

      const layerId = this.featureGroup.getLayerId(l);
      if (layerId === selectedLayer.layerId) {
        lyr = l;
      }
    });

    lyr.openPopup();
    const map = this.mapService.getMap();
    map.setView(lyr.getLatLng(), 17);
  }

  /* свободные земельные участки*/
  getFreeLandHtml(list: IFreeLand[]): string {

    let title = '';
    this.translateService.get('freeLand.title').subscribe(res => {
      title = res;
    });

    let comment = '';
    this.translateService.get('freeLand.comment').subscribe(res => {
      comment = res;
    });

    let square = '';
    this.translateService.get('freeLand.square').subscribe(res => {
      square = res;
    });

    let address = '';
    this.translateService.get('freeLand.address').subscribe(res => {
      address = res;
    });

    let specialPurpose = '';
    this.translateService.get('freeLand.specialPurpose').subscribe(res => {
      specialPurpose = res;
    });

    let landNumber = '';
    this.translateService.get('freeLand.landNumber').subscribe(res => {
      landNumber = res;
    });

    let ownershipType = '';
    this.translateService.get('freeLand.ownershipType').subscribe(res => {
      ownershipType = res;
    });

    let price = '';
    this.translateService.get('freeLand.price').subscribe(res => {
      price = res;
    });

    let guarantFe = '';
    this.translateService.get('freeLand.guarantFe').subscribe(res => {
      guarantFe = res;
    });

    let tsn = '';
    this.translateService.get('freeLand.tsn').subscribe(res => {
      tsn = res;
    });

    let locality = '';
    this.translateService.get('freeLand.locality').subscribe(res => {
      locality = res;
    });

    let taxYear = '';
    this.translateService.get('freeLand.taxYear').subscribe(res => {
      taxYear = res;
    });

    let html = '';
    list.forEach((item, index) => {
      html += '<table style="width:100%">'
        + `<tr style="font-weight: 900"><td colspan="2">${title}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${landNumber}</td><td>${item.land_number}</td></tr>`   // Номер участка
        + `<tr><td style="width:200px;font-weight: bold">${specialPurpose}</td><td>${item.appointment}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${address}</td><td>${item.square}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${locality}</td><td>${item.locality}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${square}</td><td>${item.shape_area}</td></tr>`  // Площадь
        + `<tr><td style="width:200px;font-weight: bold">${ownershipType}</td><td>${item.rights}</td></tr>`  //
        + `<tr><td style="width:200px;font-weight: bold">${price}</td><td>${item.initial_price}</td></tr>`           //
        + `<tr><td style="width:200px;font-weight: bold">${guarantFe}</td><td>${item.guarantee_fee}</td></tr>`  //
        // + `<tr><td style="width:200px;font-weight: bold">${taxYear}</td><td>${item.tax_year}</td></tr>`       //
        + `<tr><td style="width:200px;font-weight: bold">${tsn}</td><td>${item.tsn}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${comment}</td><td>${item.comment}</td></tr>`
        + `</table>`;

      if (index !== (list.length - 1)) {
        html += '<hr>';
      }

    });

    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }

    html = `<div>${html}</div>`;
    return html;
  }

  showFreeLandInfo(item: IFreeLand): void {

    if (item.geom === null || item.geom === '') {
      return;
    }
    const map = this.mapService.getMap();
    this.featureGroup.clearLayers();
    map.addLayer(this.featureGroup);

    const geometry = WKT.parse(item.geom) as Point;
    const multipolygon = geometry.coordinates;
    const positionArr: any[] = [];

    multipolygon.forEach((multi: any) => {
      const arr1: any[] = [];
      multi.forEach((multi2) => {
        const arr2: any[] = [];
        multi2.forEach(multi3 => {
          const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(multi3[0], multi3[1]));

          arr2.push([unprojPoint.lat, unprojPoint.lng]);
        });
        arr1.push(arr2);
      });

      positionArr.push(arr1);

    });

    const polygon = L.polygon(positionArr);
    this.featureGroup.addLayer(polygon);
    map.setView(this.featureGroup.getBounds().getCenter(), 17);
    const customOptions: PopupOptions =
      {
        maxWidth: 700,
        className: 'popupCustom'
      };
    let html = this.getFreeLandHtml([item]);
    html = `<div class="modal-toolbar"></div><div class="modal-content">${html}</div> `;
    const popup = L.popup(customOptions)
      .setLatLng(polygon.getBounds().getCenter())
      .setContent(`${html}`)
      .openOn(map);

    // показать точки подключения
    this.removeLayer('gu_free_land_connection_points');
    this.addLayer('gu_free_land_connection_points', `free_land_gid=${item.gid}`);

  }

  /* Функциональные зоны */
  getFuncZonesHtml(list: IFuncZones[]): string {
    let title = '';
    this.translateService.get('funcZones.title').subscribe(res => {
      title = res;
    });

    let name = '';
    this.translateService.get('funcZones.name').subscribe(res => {
      name = res;
    });

    let html = '';
    list.forEach((item, index) => {
      html += '<table style="width:100%">'
        + `<tr style="font-weight:900"><td colspan="2">${title}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${name}</td><td>${item.name}</td></tr>`
        + '</table>';

      if (index !== (list.length - 1)) {
        html += '<hr>';
      }

    });

    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }
    return html;
  }

  /*  */
  getEventsHtml(list: IEvents[]): string {
    let object = '';
    this.translateService.get('events.object').subscribe(res => {
      object = res;
    });

    let name = '';
    this.translateService.get('events.name').subscribe(res => {
      name = res;
    });

    let comment = '';
    this.translateService.get('events.comment').subscribe(res => {
      comment = res;
    });

    let date = '';
    this.translateService.get('events.date').subscribe(res => {
      date = res;
    });

    let html = '';
    list.forEach((item, index) => {
      html += '<table style="width:100%">'
        + `<tr style="font-weight: 900"><td colspan="2">${object}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${name}</td><td>${(siteSettings.language === 3) ? item.title_kaz : item.title_rus}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${comment}</td><td>${(siteSettings.language === 3) ? item.comment_kaz : item.comment_rus}</td></tr>`
        + `<tr><td style="width:200px;font-weight: bold">${date}</td><td>${item.edate}</td></tr>`
        + '</table>';

      if (index !== (list.length - 1)) {
        html += '<hr>';
      }

    });

    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }
    return html;
  }

  private getDateStr(date): string {

    if (date !== null) {
      const dt = new Date(date);
      const day = (dt.getDate()) > 9 ? dt.getDate().toString() : ('0' + dt.getDate());
      const month = (dt.getMonth() + 1) > 9 ? (dt.getMonth() + 1).toString() : ('0' + (dt.getMonth() + 1));
      const hour = (dt.getHours()) > 9 ? dt.getHours().toString() : ('0' + dt.getHours());
      const minute = (dt.getMinutes()) > 9 ? dt.getMinutes().toString() : ('0' + (dt.getMinutes() + 1));
      return `${day}.${month}.${dt.getFullYear()} ${hour}:${minute}`;
    } else {
      return '';
    }
  }

  clearLayers(): void {
    this.featureGroup.clearLayers();
    this.layerData = [];
  }

  private toggleLyr(layerName: string, sourceUrl: string, layerOpt?: ILayerGroup | ILayer): void {

    const map = this.mapService.getMap();
    let searchIndex = 0;
    let searchLyr = null;
    map.eachLayer((lyr: any) => {
      const el = lyr.options.layers;
      if (el && el === layerName) {
        ++searchIndex;
        searchLyr = lyr;
      }
    });

    if (searchIndex > 0 && searchLyr) {
      this.removeVisibleLayer(searchLyr);
    } else {
      const lyr = this.addLayerByName(sourceUrl, layerName);

      this.fillLyarOptions(lyr, layerOpt);
      lyr.addTo(map);
      this.visibleLayers.next(this.visibleLayers.getValue().concat([lyr]));

    }
  }

  private addLayerByName(
    sourceUrl: string, lyrName: string, zIndex = 10, maxZoom = 21, minZoom = 7
  ): L.TileLayer {
    return L.tileLayer
      .wms(sourceUrl + 'wms?service=WMS', {
        layers: lyrName,
        format: 'image/png',
        transparent: true,
        version: '1.1.0',
        zIndex,
        maxZoom,
        minZoom
      });
  }

  private removeVisibleLayer(searchLyr): void {
    this.mapService.getMap().removeLayer(searchLyr);
    const visibleLayersList = this.visibleLayers.getValue();
    if (visibleLayersList.length <= 1) {
      this.visibleLayers.next([]);
      return;
    }

    const indexOfRemoved = visibleLayersList.findIndex(item => item === searchLyr);
    if (indexOfRemoved !== undefined) {
      visibleLayersList.splice(indexOfRemoved, 1);
      this.visibleLayers.next(visibleLayersList);
    }

    this.mapService.getMap().off('click');
  }

  private fillLyarOptions(layer: L.TileLayer, layerOpt?: ILayerGroup | ILayer): void {
    // @ts-ignore
    layer.options.nameKk = layerOpt.nameKk;
    // @ts-ignore
    layer.options.nameRu = layerOpt.nameRu;
    // @ts-ignore
    layer.options.nameEn = layerOpt.nameEn;
  }

  removeAllVisibleLayers(): void {

    const map = this.mapService.getMap();
    this.visibleLayers.forEach((list: any) => {

      list.forEach((item: any) => {
        this.mapService.getMap().removeLayer(item);
      });

    });

    this.visibleLayers = new BehaviorSubject<any[]>([]);
  }

  addLayer(lyrName: string, cqlFilter: string): void {
    const zIndex = 10;
    const maxZoom = 21;
    const minZoom = 7;

    const map = this.mapService.getMap();
    let searchIndex = 0;
    let searchLyr = null;
    map.eachLayer((lyr: any) => {
      const el = lyr.options.layers;
      if (el && el === lyrName) {
        ++searchIndex;
        searchLyr = lyr;
      }
    });
    if (searchIndex > 0 && searchLyr) {

    } else {

      const lyr = L.tileLayer.wms(`${environment.source}/wms?service=WMS`, {
        layers: lyrName,
        format: 'image/png',
        transparent: true,
        version: '1.1.0',
        zIndex,
        maxZoom,
        minZoom,
        cql_filter: `${cqlFilter}`
      } as TileLayerOptions);
      this.fillLyarOptions2(lyr, lyrName);
      lyr.addTo(map);

      this.visibleLayers.next(this.visibleLayers.getValue().concat([lyr]));
    }
  }

  private fillLyarOptions2(layer: L.TileLayer, layerName): void {
    let lyrNameKk = '';
    let lyrNameRu = '';
    let lyrNameEn = '';
    if (layerName === 'auction_lands') {
      this.translateService.get('menu.auction').subscribe(res => {
        lyrNameRu = res;
        lyrNameKk = res;
        lyrNameEn = res;
      });
    }
    if (layerName === 'competition') {
      this.translateService.get('menu.competition').subscribe(res => {
        lyrNameRu = res;
        lyrNameKk = res;
        lyrNameEn = res;
      });
    }

    if (layerName === 'social_objects') {
      this.translateService.get('menu.socialObj').subscribe(res => {
        lyrNameRu = res;
        lyrNameKk = res;
        lyrNameEn = res;
      });
    }

    if (layerName === 'crime') {
      this.translateService.get('menu.crime').subscribe(res => {
        lyrNameRu = res;
        lyrNameKk = res;
        lyrNameEn = res;
      });
    }

    if (layerName === 'trafic_accident') {
      this.translateService.get('menu.accident').subscribe(res => {
        lyrNameRu = res;
        lyrNameKk = res;
        lyrNameEn = res;
      });
    }

    if (layerName === 'gu_free_land') {
      this.translateService.get('menu.freeLands').subscribe(res => {
        lyrNameRu = res;
        lyrNameKk = res;
        lyrNameEn = res;
      });
    }

    // @ts-ignore
    layer.options.nameKk = lyrNameKk;
    // @ts-ignore
    layer.options.nameRu = lyrNameRu;
    // @ts-ignore
    layer.options.nameEn = lyrNameEn;
  }

  checkLayer(lyrName): boolean {
    const map = this.mapService.getMap();
    let searchIndex = 0;
    let searchLyr = null;
    map.eachLayer((lyr: any) => {
      const el = lyr.options.layers;
      if (el && el === lyrName) {
        ++searchIndex;
        searchLyr = lyr;
      }
    });

    if (searchIndex > 0 && searchLyr) {
      return true;
    } else {
      return false;
    }

  }

  removeLayer(lyrName): void {

    const map = this.mapService.getMap();
    let searchIndex = 0;
    let searchLyr = null;
    map.eachLayer((lyr: any) => {
      const el = lyr.options.layers;
      if (el && el === lyrName) {
        ++searchIndex;
        searchLyr = lyr;
      }
    });

    if (searchIndex > 0 && searchLyr) {

      this.mapService.getMap().removeLayer(searchLyr);

      const visibleLayersList = this.visibleLayers.getValue();
      if (visibleLayersList.length <= 1) {
        this.visibleLayers.next([]);
        return;
      }
      const indexOfRemoved = visibleLayersList.findIndex(item => item === searchLyr);
      if (indexOfRemoved !== undefined) {
        visibleLayersList.splice(indexOfRemoved, 1);
        this.visibleLayers.next(visibleLayersList);
      }

      // remove cql filter
      if (siteSettings.filter.length > 0) {
        const rows = siteSettings.filter.filter(x => x.layer !== lyrName);
        siteSettings.filter = rows;
      }
    }
  }

  clearSearchMarkerByAddressRegister(): void {
    const map = this.mapService.getMap();
    this.removeLayer('gu_free_land_connection_points');
    this.featureGroup.clearLayers();
    // ----
    if (this.searchMarkerAddressList && this.searchMarkerAddressList.length > 0) {
      this.searchMarkerAddressList.forEach((item) => {
        map.removeLayer(item);
      });
    }

    // ----
    if (this.searchMarker !== undefined) {
      map.removeLayer(this.searchMarker);
    }

  }

  addSearchMarkerByAddressRegister(searchObj: any): void {

    const map = this.mapService.getMap();
    if (this.searchMarkerAddressList && this.searchMarkerAddressList.length > 0) {
      this.searchMarkerAddressList.forEach((item) => {
        map.removeLayer(item);
      });
    }

    searchObj.content.forEach((item, index) => {

      if (item.geom) {
        const geometry = WKT.parse(item.geom) as Point;
        const point = geometry.coordinates;
        const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(point[0], point[1]));

        const socialObj = item as ISocialObjInfo;
        const smarker = L.marker([unprojPoint.lat, unprojPoint.lng]).bindPopup(this.getSocialObjHtml([socialObj]));
        this.searchMarkerAddressList.push(smarker);
        map.addLayer(smarker);
        map.setView([unprojPoint.lat, unprojPoint.lng], 16);
      }
    });

  }

  addSearchMarker(searchObj): void {
    const map = this.mapService.getMap();
    if (this.searchMarker !== undefined) {
      map.removeLayer(this.searchMarker);
    }
    let icon1 = null;
    icon1 = L.icon({
      iconSize: [41, 53],
      iconUrl: '../../../assets/markers/default/pvlMarker.svg',
    });
    const smarker = L.marker([searchObj.y, searchObj.x], {icon: icon1}).bindPopup(searchObj.label);
    this.searchMarker = smarker;
    map.addLayer(this.searchMarker);
    map.setView([searchObj.y, searchObj.x], 16);

  }

  isExistNotRemovedLyrs(layerName): boolean {
    this.removeLayer(layerName);
    const val = this.visibleLayers.getValue();
    return val.length === 0 ? true : false;
  }

  // ---- show feature info
  showGetFeatureInfo(map: L.Map, latlng): void {

    const resp = this.getFeatureInfo(map, latlng);
    resp.subscribe(
      (data: any) => {

        if (data.features && data.features.length > 0) {
          const popup = L.popup()
            .setLatLng(latlng)
            // .setContent('<br />')
            .setContent('<div style="text-align: center;"> <img src="../../assets/images/spinner.gif" alt="preloader" style="height:18px;width:18px;padding-left:18px;"></div>')
            .openOn(map);
          const layerName = data.features['0'].id.split('.');
          this.showPopup(layerName[0], data, popup);
          this.highlightLayerObj(data);
          if (layerName[0] === 'gu_free_land') {
            this.removeLayer('gu_free_land_connection_points');
            this.addLayer('gu_free_land_connection_points', `free_land_gid=${layerName[1]}`);
          }
        } else {
          // popup.setContent('<p>Ничего не найдено</p>');
        }
      },
      error => {
        console.log('error: ', error);
        // popup.setContent('<p>Ничего не найдено ' + error.message + '</p>');
      }
    );
  }

  private async highlightLayerObj(data: any) {
    const features = data.features['0']
    const geomety = features.geometry
    if (!features || !geomety) {
      return;
    }
    this.clearEditableLayers();
    this.drawGeometryOnMap(geomety, true);
  }

  initMap() {
    this.map = this.mapSvc.getMap();
    if (!this.map) {
      return;
    }
    this.map.addLayer(this.editableLayers);

    this.drawSubject.subscribe((val: any) => {

      if (val.drawEvent === 'drawend') {
        this.clearEditableLayers(false);
        this.editableLayers.addLayer(val.layer);
        this.prepareWktGeom(val.type);
      }
    });
  }

  clearEditableLayers(isClear: boolean = true) {

    if (!isClear) {
      return;
    }

    if (this.editableLayers && this.editableLayers.getLayers().length > 0) {
      this.editableLayers.clearLayers();
    }

    if (this.selectedObj) {
      this.map.removeLayer(this.selectedObj);
    }

  }
    
  drawGeometryOnMap(geometry: any, withMeasurements = false) {
    if (geometry.type.toUpperCase() === 'POINT' || geometry.type.toUpperCase() === 'MULTIPOINT') {
      this.drawPointOnMap(geometry, false);
    } else {
      geometry.coordinates.map(coordinates => {
        const coords = this.parseCoords(coordinates);
        const layer: any = this.fetchLayerOfGeometry(
          geometry.type,
          coords
        );
        if (!layer) {
          return;
        }
        this.editableLayers.addLayer(layer);
        if (!this.map) {
          this.initMap();
        }
        if (withMeasurements) {
          setTimeout(() => {
            layer.showMeasurements();
          }, 100);
          return;
        }
        this.map.fitBounds(layer.getBounds());
      });
    }
  }

  private drawPointOnMap(geometry: Geometry, isZoom: boolean = true) {
    const customMarker = this.getCustomMarker();
    // @ts-ignore
    const coords = geometry.coordinates;
    const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(coords[0], coords[1]));

    const marker = L.marker(unprojPoint, {
      icon: new customMarker()
    });
    this.drawMarker = marker;

    // if (isZoom) {
    //   this.geomSvc.animatedZoom2Point(this.map,
    //     [unprojPoint.lat, unprojPoint.lng], 18, 1);
    // }

    // this.editableLayers.addLayer(marker);
  }

  public fetchLayerOfGeometry(geometryType: string, coords: any[]) {
    const geomType = geometryType.toUpperCase();
    switch (geomType) {
      case GEOMETRYTYPES.POLYGON:
      case GEOMETRYTYPES.MULTIPOLYGON:
        return L.polygon(coords);
      case GEOMETRYTYPES.LINESTRING:
      case GEOMETRYTYPES.MULTILINESTRING:
        return L.polyline(coords);
      case GEOMETRYTYPES.POINT:
        return L.point(coords[0], coords[1]);
      case GEOMETRYTYPES.MULTIPOINT:
        const greenIcon = L.icon({
          iconUrl: '../../../../assets/images/markers/default/marker-icon.png',
          iconSize: [25, 41],
        });
        return L.marker([coords[0], coords[1]], { icon: greenIcon });
      default: return null;
    }
  }

  getCustomMarker() {
    const CustomMarker = L.Icon.extend({
      options: {
        iconUrl: '../../assets/images/markers/default/marker-icon.png',
        iconRetinaUrl: '../../assets/images/markers/default/marker-icon-2x.png',
        shadowUrl: '../../assets/images/markers/default/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -32],
        shadowSize: [41, 41]
      }
    });
    return CustomMarker;
  }

  private parseCoords(coordinates: any[]) {
    let coords = [...coordinates];
    // coords = this.geomSvc.convertCoordsProjectionEPSG(coords);
    coords = this.unprojCoordsArray(coords);
    if (typeof coords === 'object') {
      return coords.map((e) => this.swapLatAndLng(e));
    }
    return this.swapLatAndLng([coords[0], coords[1]]);
    // return [coords[0], coords[1]];
  }

  public unprojCoordsArray(coordinates: any[]) {
    const coords = coordinates.length === 1 ? coordinates[0] : coordinates;
    coords.forEach((item) => {
      const pointLatLng = L.Projection.SphericalMercator.unproject(L.point(item[0], item[1]));
      item[0] = pointLatLng.lng;
      item[1] = pointLatLng.lat;
    });
    return coords;
  }

  public swapLatAndLng(latlngs: number[]) {
    return latlngs.reverse();
  }

  prepareWktGeom(lyrType) {
    if (this.editableLayers.getLayers().length > 0) {
      const layer = this.editableLayers.getLayers()[0];
      // const wkt = this.geomSvc.toWKT(layer, 'MULTIPOLYGON', true);
      const wkt = this.toWKT(layer, lyrType, true);
      this.preparedWktString.next(wkt);
    }
  }

  public toWKT(layer: L.Layer, layerType: string, project) {
    if (layer instanceof L.Polygon) {
      return this.fetchWKTOfLayer('MULTIPOLYGON', this.fetchWKTCoordsOfLayer(layer, project));
    } else if (layer instanceof L.Polyline) {
      return this.fetchWKTOfLayer('MULTILINESTRING', this.fetchWKTCoordsOfLayer(layer, project));
    } else if (layer instanceof L.Marker && !project) {
      return layerType + '(' + layer.getLatLng().lng + ' ' + layer.getLatLng().lat + ')';
    } else if (layer instanceof L.Marker && project) {
      const projPoint = L.Projection.SphericalMercator.project(layer.getLatLng());
      return 'MULTIPOINT(' + projPoint.x + ' ' + projPoint.y + ')';
    } else if (layer instanceof L.Marker && project) {
      const projPoint = L.Projection.SphericalMercator.project(layer.getLatLng());
      return 'POINT(' + projPoint.x + ' ' + projPoint.y + ')';
    }
  }

  private fetchWKTOfLayer(layerType: string, coordsContent) {
    switch (layerType) {
      case GEOMETRYTYPES.POINT:
        return `${layerType}(${coordsContent})`;
      case GEOMETRYTYPES.MULTIPOINT:
        return `${layerType}(${coordsContent})`;
      case GEOMETRYTYPES.LINESTRING:
        return `${layerType}(${coordsContent})`;
      case GEOMETRYTYPES.MULTILINESTRING:
        return `${layerType}((${coordsContent}))`;
      case GEOMETRYTYPES.POLYGON:
        return `${layerType}((${coordsContent}))`;
      case GEOMETRYTYPES.MULTIPOLYGON:
        return `${layerType}(((${coordsContent})))`;
      default:
        return `${layerType}(${coordsContent})`;
    }
  }

  private fetchWKTCoordsOfLayer(layer: any, project) {
    const coords = [];
    let lat: string;
    let lng: string;
    const latlngs: any = layer.getLatLngs();
    for (let i = 0; i < latlngs.length; i++) {
      const latlngs1: any = latlngs[i];
      if (latlngs1.length) {
        for (let j = 0; j < latlngs1.length; j++) {
          if (project) {
            const latlngsProjected: any = L.Projection.SphericalMercator.project(latlngs1[j]);
            coords.push(latlngsProjected.x + ' ' + latlngsProjected.y);
          } else {
            coords.push(latlngs1[j].lng + ' ' + latlngs1[j].lat);
          }
          if (j === 0) {
            if (project) {
              const latlngsProjec: any = L.Projection.SphericalMercator.project(latlngs1[j]);
              lng = latlngsProjec.x;
              lat = latlngsProjec.y;
            } else {
              lng = latlngs1[j].lng;
              lat = latlngs1[j].lat;
            }
          }
        }
      } else {
        if (project) {
          const latlngsProjected: any = L.Projection.SphericalMercator.project(latlngs1); // latlngs1[i]
          coords.push(latlngsProjected.x + ' ' + latlngsProjected.y);
        } else {
          coords.push(latlngs[i].lng + ' ' + latlngs[i].lat);
        }
        if (i === 0) {
          if (project) {
            const latlngsProjec: any = L.Projection.SphericalMercator.project(latlngs[i]);
            lng = latlngsProjec.x;
            lat = latlngsProjec.y;
          } else {
            lng = latlngs[i].lng;
            lat = latlngs[i].lat;
          }
        }
      }
    }
    if (layer instanceof L.Polygon) {
      return `${coords.join(',')},${lng} ${lat}`;
    } else if (layer instanceof L.Polyline) {
      return `${coords.join(',')}`;
    }
    return `${coords.join(',')},${lng} ${lat}`;
  }


  getFeatureInfo(map: L.Map, latlng: any): any {
    const baseUrl = environment.source + '/wms?';
    const url = this.getFeatureInfoUrl(baseUrl, map, latlng);
    return this.http.get(url);
  }

  private getFeatureInfoUrl(baseurl: string, map: L.Map, latlng: L.LatLng): string {

    const layerList = [];
    const cqlFilter = [];
    this.visibleLayers.getValue().forEach((item: any) => {

      layerList.push(item.options.layers);
      const row = siteSettings.filter.find(x => x.layer === item.options.layers);
      if (row !== undefined) {
        cqlFilter.push(row.cql);
      } else {
        cqlFilter.push('1=1');
      }

    });

    const cqlStr = cqlFilter.join(';');
    const point = map.latLngToContainerPoint(latlng);
    const size = map.getSize();
    const params = {
      request: 'GetFeatureInfo',
      service: 'WMS',
      srs: 'EPSG:3857',
      transparent: true,
      version: '1.1.1',
      format: 'image/png',
      bbox: this.getMercatorBBoxString(map),
      height: size.y,
      width: size.x,
      layers: layerList,
      query_layers: layerList,
      FEATURE_COUNT: 10,
      info_format: 'application/json',
      CQL_FILTER: cqlStr
    };

    params[params.version === '1.3.0' ? 'i' : 'x'] = Math.round(point.x);
    params[params.version === '1.3.0' ? 'j' : 'y'] = Math.round(point.y);

    return baseurl + L.Util.getParamString(params, baseurl, true);
  }

  getMercatorBBoxString(map: L.Map): string {
    const bounds = map.getBounds();
    const west = L.Projection.SphericalMercator.project(bounds.getNorthWest()).x;
    const south = L.Projection.SphericalMercator.project(bounds.getSouthWest()).y;
    const east = L.Projection.SphericalMercator.project(bounds.getNorthEast()).x;
    const north = L.Projection.SphericalMercator.project(bounds.getNorthWest()).y;
    return west + ',' + south + ',' + east + ',' + north + '';
  }

  showPopup(lyrName: string, res: any, popup: any): void {

    this.layersApiService.getLayerAttributes(lyrName, 1).subscribe(data => {

      const objList: any = [];
      res.features.forEach((r) => {

        if (lyrName === 'gu_free_land') {
          const prop = r.properties as IFreeLand;
          objList.push(prop);
        } else if (lyrName === 'crime') {
          const prop = r.properties as ICrimeObj;
          objList.push(prop);
        } else if (lyrName === 'trafic_accident') {
          const prop = r.properties as IDtp;
          objList.push(prop);
        } else if (lyrName === 'auction_lands') {
          const prop = r.properties as IAuction;
          objList.push(prop);
        } else if (lyrName === 'competition') {
          const prop = r.properties as ICompetition;
          objList.push(prop);
        } else if (lyrName === 'social_objects') {
          const prop = r.properties as ISocialObjInfo;
          objList.push(prop);
        } else if (lyrName === 'func_zones') {
          const prop = r.properties as IFuncZones;
          objList.push(prop);
        } else if (lyrName === 'events') {
          const prop = r.properties as IEvents;
          objList.push(prop);
        } else {
          objList.push(r);
        }
      });

      let html = '';
      let isExistCard = false;
      if (lyrName === 'gu_free_land') {
        html = this.getFreeLandHtml(objList);
        isExistCard = true;
      } else if (lyrName === 'crime') {
        html = this.getCrimeHtml(objList);
        isExistCard = true;
      } else if (lyrName === 'trafic_accident') {
        html = this.getDtpHtml(objList);
        isExistCard = true;
      } else if (lyrName === 'auction_lands') {
        html = this.getAuctionHtml(objList);
        isExistCard = true;
      } else if (lyrName === 'competition') {
        html = this.getCompetitionHtml(objList);
        isExistCard = true;
      } else if (lyrName === 'social_objects') {
        html = this.getSocialObjHtml(objList);
        isExistCard = true;
      } else if (lyrName === 'func_zones') {
        html = this.getFuncZonesHtml(objList);
        isExistCard = true;
      } else if (lyrName === 'events') {
        html = this.getEventsHtml(objList);
        isExistCard = true;
      }

      html = `<div class="modal-toolbar"></div><div class="modal-content">${html}</div> `;
      if (isExistCard) {
        popup.setContent(html);
      } else {
        html = this.fillHtml(data, objList);
        popup.setContent(html);
      }

    });
  }

  fillHtml(data: any[], objList: any[]): string {

    let html = '';
    objList.forEach((item, index) => {
      html += '<table style="width:100%">';
      data.forEach((attr, attrIndex) => {

        if (siteSettings.language === Language.KAZ) {
          if (item.properties[attr.attrName]) {
            html += `<tr style="font-weight: 900"><td>${attr.nameKk}</td><td>${item.properties[attr.attrName]}</td></tr>`;
          }
        } else if (siteSettings.language === Language.ENG) {
          if (item.properties[attr.attrName]) {
            html += `<tr style="font-weight: 900"><td>${attr.nameEn}</td><td>${item.properties[attr.attrName]}</td></tr>`;
          }
        } else {
          if (item.properties[attr.attrName]) {
            html += `<tr style="font-weight: 900"><td>${attr.nameRu}</td><td>${item.properties[attr.attrName]}</td></tr>`;
          }
        }
      });

      html += `</table>`;
      if (index !== (objList.length - 1)) {
        html += '<hr>';
      }

    });

    while (html.indexOf('null') !== -1) {
      html = html.replace(null, '');
    }

    while (html.indexOf('undefined') !== -1) {
      html = html.replace(undefined, '');
    }

    html = `<div>${html}</div>`;
    return html;
  }

  heatMap(data: any[]): void {
    const map = this.mapService.getMap();
    this.mapControlsService.initHead(map, data);
  }

  removeHeatMap(): void {
    const map = this.mapService.getMap();
    this.mapControlsService.removeHeatMap(map);
  }

  // ---- old method of show feature info
  featureInfoWMS(lyrName: string, map: L.Map, e: LeafletMouseEvent, CQL_FILTER: string): void {
    if (this.checkLayer(lyrName)) {
      const sw = map.options.crs.project(map.getBounds().getSouthWest());
      const ne = map.options.crs.project(map.getBounds().getNorthEast());
      const BBOX = sw.x + ',' + sw.y + ',' + ne.x + ',' + ne.y;
      const WIDTH = map.getSize().x;
      const HEIGHT = map.getSize().y;

      const X = Math.trunc(map.layerPointToContainerPoint(e.layerPoint).x);
      const Y = Math.trunc(map.layerPointToContainerPoint(e.layerPoint).y);
      // tslint:disable-next-line:max-line-length
      const params = 'SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS=' + lyrName + '&QUERY_LAYERS=' + lyrName + '&CQL_FILTER=' + CQL_FILTER + '&BBOX=' + BBOX + '&FEATURE_COUNT=1000&HEIGHT=' + HEIGHT + '&WIDTH=' + WIDTH + '&INFO_FORMAT=application%2Fjson&TILED=false&CRS=EPSG%3A3857&I=' + X + '&J=' + Y;

      this.portalApiService.getFeatureInfoWMS(params).subscribe(res => {

        if (res && res.features.length > 0) {

          const objList: any = [];
          res.features.forEach((r) => {

            if (lyrName === 'gu_free_land') {
              const prop = r.properties as IFreeLand;
              objList.push(prop);
            } else if (lyrName === 'crime') {
              const prop = r.properties as ICrimeObj;
              objList.push(prop);
            } else if (lyrName === 'trafic_accident') {
              const prop = r.properties as IDtp;
              objList.push(prop);
            } else if (lyrName === 'auction_lands') {
              const prop = r.properties as IAuction;
              objList.push(prop);
            } else if (lyrName === 'competition') {
              const prop = r.properties as ICompetition;
              objList.push(prop);
            } else if (lyrName === 'social_objects') {
              const prop = r.properties as ISocialObjInfo;
              objList.push(prop);
            } else if (lyrName === 'func_zones') {
              const prop = r.properties as IFuncZones;
              objList.push(prop);
            } else if (lyrName === 'events') {
              const prop = r.properties as IEvents;
              objList.push(prop);
            }
          });

          let html = '';
          let isExistCard = false;
          if (lyrName === 'gu_free_land') {
            html = this.getFreeLandHtml(objList);
            isExistCard = true;
          } else if (lyrName === 'crime') {
            html = this.getCrimeHtml(objList);
            isExistCard = true;
          } else if (lyrName === 'trafic_accident') {
            html = this.getDtpHtml(objList);
            isExistCard = true;
          } else if (lyrName === 'auction_lands') {
            html = this.getAuctionHtml(objList);
            isExistCard = true;
          } else if (lyrName === 'competition') {
            html = this.getCompetitionHtml(objList);
            isExistCard = true;
          } else if (lyrName === 'social_objects') {
            html = this.getSocialObjHtml(objList);
            isExistCard = true;
          } else if (lyrName === 'func_zones') {
            html = this.getFuncZonesHtml(objList);
            isExistCard = true;
          } else if (lyrName === 'events') {
            html = this.getEventsHtml(objList);
            isExistCard = true;
          }

          if (isExistCard) {
            L.popup().setLatLng(e.latlng).setContent(html).openOn(map);
          }
        }
      });
    }
  }
}

export interface Geometry {
  type: string;
  coordinates: any;
}

