import {Component, HostListener, OnInit} from '@angular/core';
import {MapService} from 'src/app/shared/services/map.service';
import * as L from 'leaflet';
import siteSettings from '../../shared/config/site-settings';
import {LayersManagerService} from '../../shared/services/layers-manager.service';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-zoom-in-out',
  templateUrl: './zoom-in-out.component.html',
  styleUrls: ['./zoom-in-out.component.scss'],
})
export class ZoomInOutComponent implements OnInit {
  toolsShow = false;
  copyBody;

  constructor(private mapSvc: MapService,
              private layersManagerService: LayersManagerService) {
  }

  ngOnInit(): void {
  }

  changeZoom(state: boolean): void {
    const map = this.mapSvc.getMap();
    let zoomLevel = map.getZoom();
    map.setZoom(state ? ++zoomLevel : --zoomLevel);
  }

  extentZoom(): void {
    const map = this.mapSvc.getMap();
    map.setView([52.292, 76.959], 12);
  }

  locateUser(): void {
    const map = this.mapSvc.getMap();
    if (navigator.geolocation) {
      this.mapSvc.clearEditableLayers();
      navigator.geolocation.getCurrentPosition((position) => {
        L.circle([position.coords.latitude, position.coords.longitude], {
          radius: 29,
          color: 'blue',
        }).addTo(this.mapSvc.editableLayers);
        map.setView([position.coords.latitude, position.coords.longitude], 17);
      });
    } else {
      alert('Ваш браузер не поддерживает HTML5 Geolocation');
    }
  }

  // clearLayers(): void {
  //   // this.layersManagerService.addLayer('crime', '1=1');
  //   this.layersManagerService.clearSearchMarkerByAddressRegister();
  //   siteSettings.isClearAllLyrs = true;
  //   // this.layersManagerService.removeAllVisibleLayers();
  // }

  tools(): void {
    this.toolsShow = !this.toolsShow;

    const measure = document.querySelector('div.leaflet-control-measure') as HTMLDivElement;
    const rule = document.querySelector('div.leaflet-ruler') as HTMLDivElement;
    if (this.toolsShow) {
      measure.style.display = 'block';
      rule.style.display = 'block';
    } else {
      measure.style.display = 'none';
      rule.style.display = 'none';
    }

  }

  print(): void {
    this.printMaps();
  }

  printMaps(): void {

    const body = document.querySelector('body') as HTMLBodyElement;
    this.copyBody = body.innerHTML;
    const map = document.querySelector('div.leaflet-container') as HTMLDivElement;
    body.innerHTML = map.innerHTML;
    window.print();
    // body.innerHTML = this.copyBody;
    // document.location.reload();
  }

  @HostListener('window:afterprint')
  // tslint:disable-next-line:typedef
  onafterprint() {
    const body = document.querySelector('body') as HTMLBodyElement;
    // body.innerHTML = this.copyBody;
    document.location.reload();
  }

  screen(): void {
    (document.querySelector('.leaflet-ruler') as HTMLElement).style.display = 'none';
    (document.querySelector('.leaflet-control-measure') as HTMLElement).style.display = 'none';
    const mapContainer = document.getElementById('mapid');
    html2canvas(mapContainer, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const data = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const element = document.createElement('a');
      element.setAttribute('href', data);
      element.setAttribute('download', 'map.png');
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
    (document.querySelector('.leaflet-ruler') as HTMLElement).style.display = 'block';
    (document.querySelector('.leaflet-control-measure') as HTMLElement).style.display = 'block';
  }

  measureDistance(): void {
  }

  measureArea(): void {
  }

  getCoordinates(): void {
    siteSettings.isCoordinate = true;
  }

  // generateMapScreen(): void {
  //   console.log('settings');
  //   const mapContainer = document.getElementById('mapid');
  //   html2canvas(mapContainer, {
  //     useCORS: true
  //   }).then((canvas) => {
  //     const jpegUrl = canvas.toDataURL('image/jpeg');
  //     const arrImg = jpegUrl.split(',');
  //     // this.base64textString = arrImg[arrImg.length - 1];
  //     // this.mapControlsSvc.hideControls(false);
  //   }).catch(e => console.log(e));
  // }
}
